<script setup lang="ts">
import { ExpandOutlined } from '@ant-design/icons-vue'
import Card from './general/card.vue'
import OnOffLine from './general/on-off-line.vue'
import Warn from './general/warn.vue'
import { getDeviceModelsApi } from '@/api/device-model'
import { queryDevicesApi } from '@/api/device'
import { getDriverInstancesApi } from '@/api/driver'
import { getDeviceOnlineBySubsysStatisticApi } from '@/api/statistic'
import cardBlue from '@/assets/images/card-blue.png'
import cardWarn from '@/assets/images/card-warn.png'
import cardGreen from '@/assets/images/card-green.png'
import cardDanger from '@/assets/images/card-danger.png'
import cardGray from '@/assets/images/card-gray.png'
import blueIcon from '@/assets/images/card-blue-icon.svg'
import warnIcon from '@/assets/images/card-warn-icon.svg'
import greenIcon from '@/assets/images/card-green-icon.svg'
import dangerIcon from '@/assets/images/card-danger-icon.svg'
import grayIcon from '@/assets/images/card-gray-icon.svg'
import { queryPlatformRuntimeEventsApi } from '@/api/event'
import { onFullScreen } from '@/utils/screen'

const deviceModels = ref<DeviceModel[]>([])
const pagingDevices = ref<DevicePaging<Device>>({
  current: 1,
  total: 0,
  size: 20,
  records: [],
})
const driverInstances = ref<any[]>([])
const onlineOffline = ref<CountDeviceOnlineBySubSys>({
  online: 0,
  offline: 0,
  subSys: [],
  total: 0,
})
const alarmAmount = ref(0)

function calculatePercentage(part: number, total: number) {
  if (total === 0) {
    return 0 // 避免除以零的情况
  }
  const percentage = (part / total) * 100 // 计算百分比
  return Number(percentage.toFixed(0)) // 保留两位小数并转换为字符串
}

onMounted(getDeviceModels)

async function getDeviceModels() {
  deviceModels.value = await getDeviceModelsApi()
}

onMounted(getDevices)
async function getDevices() {
  pagingDevices.value = await queryDevicesApi({ current: 1, size: 20 })
}

onMounted(getDriverInstances)
async function getDriverInstances() {
  driverInstances.value = await getDriverInstancesApi()
}

onMounted(getDeviceOnOffLine)

async function getDeviceOnOffLine() {
  onlineOffline.value = await getDeviceOnlineBySubsysStatisticApi()
}

onMounted(queryRuntimeEvents)

async function queryRuntimeEvents() {
  const res = await queryPlatformRuntimeEventsApi({ confirmed: false, eventTypes: ['WARN'] } as RuntimeEventParam)
  alarmAmount.value = res.list.length
}
</script>

<template>
  <page-container id="out-dom">
    <content-header title="设备概况">
      <template #right>
        <ExpandOutlined style="font-size: 20px;cursor: pointer;" @click="onFullScreen('#out-dom')" />
      </template>
    </content-header>
    <div flex-1 flex flex-col p-12px style="background: var(--bg-color);">
      <flex-content>
        <div h-full w-full overflow-hidden flex flex-col>
          <div flex-none text-14px font-bold pb-10px>
            实时监控
          </div>
          <div flex flex-none>
            <Card :bordered="false" title="产品数量" :icon-path="blueIcon" :style="{ color: 'rgb(4, 122, 255)' }" bg-color="rgba(4, 122, 255, .1)" :bg-url="cardBlue" :content-height="0" flex-1 mr-10px>
              <template #total>
                <span>{{ deviceModels.length }}</span>
              </template>
            </Card>
            <Card :bordered="false" title="设备数量" :icon-path="warnIcon" :style="{ color: 'rgb(255, 168, 0)' }" bg-color="rgba(255, 168, 0, .1)" :bg-url="cardWarn" :content-height="0" flex-1 mr-10px>
              <template #total>
                <span>{{ onlineOffline.total }}</span>
              </template>
            </Card>
            <Card :bordered="false" title="在线设备数量" :icon-path="greenIcon" :style="{ color: 'rgb(0, 223, 103)' }" bg-color="rgba(0, 223, 103, .1)" :bg-url="cardGreen" :content-height="0" flex-1 mr-10px>
              <template #total>
                <span>{{ onlineOffline.online }}</span>
              </template>
              <template #action>
                <a-progress :percent="calculatePercentage(onlineOffline.online, onlineOffline.total)" stroke-color="rgb(0, 223, 103)" trail-color="#c9ffe1" />
              </template>
            </Card>
            <Card :bordered="false" title="离线设备数量" :icon-path="grayIcon" :style="{ color: 'rgb(179, 179, 179)' }" bg-color="hsla(0, 0%, 60%, .1)" :bg-url="cardGray" :content-height="0" flex-1 mr-10px>
              <template #total>
                <span>{{ onlineOffline.offline }}</span>
              </template>
              <template #action>
                <a-progress :percent="calculatePercentage(onlineOffline.offline, onlineOffline.total)" stroke-color="rgb(153, 153, 153)" trail-color="#e6e6e6" />
              </template>
            </Card>
            <Card :bordered="false" title="告警设备数量" :icon-path="dangerIcon" :style="{ color: 'rgb(255, 112, 67)' }" bg-color="rgba(255, 112, 67, .1)" :bg-url="cardDanger" :content-height="0" flex-1>
              <template #total>
                <span>{{ alarmAmount }}</span>
              </template>
            </Card>
          </div>

          <div flex-none text-14px font-bold mt-16px mb-10px>
            历史记录
          </div>
          <div flex-1>
            <div class="echarts-part" pb-5px>
              <OnOffLine />
            </div>
            <div class="echarts-part" pt-5px>
              <Warn />
            </div>
          </div>
        </div>
      </flex-content>
    </div>
  </page-container>
</template>

<style lang="scss" scoped>
.echarts-part{
  height: 50%;
}
</style>
