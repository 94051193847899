<script setup lang="ts">
import { historyPlatformEventsLastDaysTrendApi } from '@/api/device'

const option = ref({})

onMounted(refresh)

async function refresh() {
  const res = await historyPlatformEventsLastDaysTrendApi()
  const colors = ['rgb(255, 168, 0)', 'rgb(255, 112, 67)']
  res.series.forEach((item: any, index: number) => {
    item.type = 'line'
    item.itemStyle = {
      color: colors[index],
    }
  })

  option.value = {
    title: {
    //   text: 'Stacked Line',
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
    //   data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: res.dates,
    },
    yAxis: {
      type: 'value',
    },
    series: res.series,
  }
}
</script>

<template>
  <a-card h-full :body-style="{ height: '100%' }">
    <div v-if="Object.keys(option).length > 0" h-full>
      <echarts :option="option" />
    </div>
  </a-card>
</template>

<style lang="scss" scoped>
:deep(.ant-card){
  .ant-card-body{
    height: 100%;
  }
}
</style>
