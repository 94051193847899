<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  total: {
    type: Number,
  },
  contentHeight: {
    type: Number,
  },
  bgColor: { type: String },
  bgUrl: { type: String },
  iconPath: { type: String },
})
</script>

<template>
  <a-card :bind="props" :body-style="{ padding: '0' }" overflow-hidden>
    <div h-180px p-16px>
      <div flex flex-col>
        <img :src="iconPath" w-40px h-40px>
        <span>{{ title }}</span>
      </div>
      <div mt-6px text-40px style="font-weight: bolder;">
        <slot name="total" />
      </div>
      <div>
        <slot name="action" />
      </div>
    </div>
    <div pos-absolute top-0 left-0 w-full h-full class="card-bg" :style="{ backgroundImage: `url(${bgUrl})`, backgroundColor: `${bgColor} ` }" />
  </a-card>
</template>

<style scoped lang="scss">
.card-bg{
  position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: all .3s;

    &:hover{
        transform: scale(1.1);
    }
}
</style>
